<template>
  <q-layout
    view="lHr lpR lFr"
    :style="{ backgroundImage: `url(${bg})`, minHeight: '500px' }"
    class="img-overlay auth"
  >
    <q-page-container>
      <q-page>
        <img
          src="@/assets/rp.svg"
          width="128px"
          class="auth-logo"
          :class="{ active: isLogoActive }"
        />
        <div class="absolute-full text-subtitle2 flex flex-center">
          <transition
            enter-active-class="animated fadeInLeft"
            leave-active-class="animated fadeOut"
          >
            <RouterView />
          </transition>
        </div>
        <q-card class="flex rights justify-between items-center">
          <span class="q-gutter-x-md">
            <q-btn flat dense no-caps @click.prevent="$bus.emit('open-terms')">
              Правила и соглашения
            </q-btn>
            <q-btn flat dense no-caps @click.prevent="$bus.emit('open-terms')">
              Помощь
            </q-btn>
          </span>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { useQuasar } from "quasar";
import { inject, ref } from "vue";

const rand = Math.floor(Math.random() * 5) + 1;
const bg = require(`@/assets/bgs/start_${rand}.jpg`);
const $q = useQuasar();
const isLogoActive = ref(false);
const $bus = inject("bus");
$q.dark.set(false);

setTimeout(() => {
  isLogoActive.value = true;
}, 500);
</script>
